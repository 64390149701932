import { motion } from 'framer-motion'

const initial = { opacity: 0, y: 40 }
const whileInView = { opacity: 1, y: 0 }
const viewport = { once: true }
const transition = { duration: 0.4, delay: 0.1 }

const team = [
  {
    name: 'علیرضا سجادی',
    role: 'مدیر محتوا',
    img: '/images/alireza-sajadi.jpg',
  },
  {
    name: 'علیرضا عنایتی',
    role: 'مشاور برندینگ',
    img: '/images/alireza-enayati.jpg',
  },
  {
    name: 'نریمان موفقی',
    role: 'پشتیبان سایت',
    img: '/images/nariman.jpg',
  },
  {
    name: 'امیرمحمد حیدری',
    role: 'طراح سایت',
    img: '/images/team 2.JPG',
  },
]

export const Team = () => {
  return (
    <section className="py-[80px]">
      <div data-wow-duration="0.7s" className="container mx-auto sm:px-4 flex flex-col gap-8 md:gap-12">
        <motion.h2 className="h2-subtitle" initial={initial} whileInView={whileInView} viewport={viewport} transition={transition}>
          تیم مآریان
        </motion.h2>
        <div className="flex sm:flex-row flex-wrap flex-col gap-x-4 gap-y-8 space-x-reverse">
          {team.map((teamMember, index) => {
            return (
              <motion.div
                initial={initial}
                whileInView={whileInView}
                viewport={viewport}
                transition={{
                  ...transition,
                  delay: transition.delay + index / 5,
                }}
                className="sm:basis-[calc(50%-12px)] md:basis-[calc(25%-12px)]"
                key={teamMember.name}
              >
                <div className="wow animate__fadeInUp flex flex-col gap-4 md:gap-6" data-wow-delay=".5s">
                  <div className="team-img-box team-border-one">
                    <div className="team-img">
                      <img src={teamMember.img} alt={teamMember.name} className="rounded-md opacity-80 w-full aspect-square object-cover object-center" />
                    </div>
                  </div>
                  <div className="flex flex-col gap-0">
                    <a href="team-detail.html">
                      <span className="text-white">{teamMember.name}</span>
                    </a>
                    <span className="text-gray-400">{teamMember.role}</span>
                  </div>
                </div>
              </motion.div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

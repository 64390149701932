import { motion } from 'framer-motion'

const initial = { opacity: 0, y: 40 }
const whileInView = { opacity: 1, y: 0 }
const viewport = { once: true }
const transition = { duration: 0.4, delay: 0.3 }

export const AboutUs = () => {
  return (
    <section className="bg-black md:mt-40" id="M2">
      <div className="container mx-auto sm:px-4 flex flex-col justify-start gap-[100px] py-20 bg-black">
        <div data-wow-duration="0.8s" className="wow animate__fadeInUp w-full md:grid grid-cols-12 gap-14 flex flex-col">
          <div className="col-span-6 flex flex-col gap-14 justify-center md:justify-center">
            <motion.h2
              initial={initial}
              whileInView={whileInView}
              viewport={viewport}
              transition={transition}
              className="text-2xl md:text-4xl font-semibold text-white text-center md:text-right font-header"
            >
              درباره ما
            </motion.h2>
            <div className="flex flex-col gap-10">
              <div className="flex flex-col">
                <motion.p initial={initial} whileInView={whileInView} viewport={viewport} transition={transition} className="text-white font-semibold text-lg">
                  مآریان
                </motion.p>
                <motion.p initial={initial} whileInView={whileInView} viewport={viewport} transition={transition} className="text-gray-200 font-normal text-md text-justify">
                  برند ورزشی مآریان از عشق و اعتقاد به سبک زندگی فعال و سالم شکل گرفته است. با ترکیب ایده‌آل کیفیت و اهمیت در هر جزئیات، مآریان به عنوان یک نماد از تعهد به سلامتی و ورزش، به طور مداوم
                  به رشد و توسعه می‌پردازد.
                </motion.p>
              </div>
              <div className="flex flex-col">
                <motion.p initial={initial} whileInView={whileInView} viewport={viewport} transition={transition} className="text-white font-semibold text-lg">
                  هدف مآریان
                </motion.p>
                <motion.p initial={initial} whileInView={whileInView} viewport={viewport} transition={transition} className="text-gray-200 font-normal text-md text-justify">
                  هدف اصلی مآریان ارائه‌ی بهترین محصولات و خدمات ورزشی با بالاترین کیفیت و اهمیت به مشتریان است. ما در این مجموعه برای ارتقاء سلامتی و عملکرد فیزیکی شما تلاش می کنیم و با توجه به
                  نیازها و انتظارات شما، به بهبود مستمر خدمات می پردازیم.
                </motion.p>
              </div>
              <div className="flex flex-col">
                <motion.p initial={initial} whileInView={whileInView} viewport={viewport} transition={transition} className="text-white font-semibold text-lg">
                  مؤسس
                </motion.p>
                <motion.p initial={initial} whileInView={whileInView} viewport={viewport} transition={transition} className="text-gray-200 font-normal text-md text-justify">
                  <b>سجاد شکری</b>، مؤسس برند مآریان، با علاقه به ورزش و سلامتی، این برند را بر اساس ارزش‌های خود شکل داده است. با چشم اندازی قوی و هدفمند، او به ایجاد محیطی موثر برای توسعه ورزش در
                  جامعه می‌پردازد و از طریق مآریان، مشتریان را به یک زندگی سالم و فعال هدایت می‌کند.
                </motion.p>
              </div>
            </div>
          </div>
          <div className="col-span-6 grid grid-cols-2 md:gap-6 gap-3 opacity-70">
            <div className="flex flex-col md:gap-6 gap-3">
              <motion.img
                initial={{ opacity: 0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4 }}
                src="/images/gallery-4.jpg"
                alt="gallery-1"
                className="rounded-md transform mt-[80px]"
              />
              <motion.img
                initial={{ opacity: 0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4 }}
                src="/images/gallery-1.jpg"
                alt="gallery-1"
                className="rounded-md transform"
              />
            </div>
            <div className="flex flex-col md:gap-6 gap-3">
              <motion.img
                initial={{ opacity: 0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4 }}
                src="/images/gallery-6.jpg"
                alt="gallery-1"
                className="rounded-md transform translate-y-[0px]"
              />
              <motion.img
                initial={{ opacity: 0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4 }}
                src="/images/gallery-2.jpg"
                alt="gallery-1"
                className="rounded-md transform translate-y-[0px]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

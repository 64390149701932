import { Link } from '@remix-run/react'
import { menu } from '~/components/navbar'
import IcBaselineEmail from '~icons/ic/baseline-email'
import IcBaselineLocalPhone from '~icons/ic/baseline-local-phone'
import IcSharpLocationOn from '~icons/ic/sharp-location-on'
import MdiInstagram from '~icons/mdi/instagram'
import MdiTelegram from '~icons/mdi/telegram'

export const Footer = () => {
  return (
    <footer className="container md:mt-10 " id="M5">
      <div className="mx-auto sm:px-4">
        <div className="flex flex-wrap gap-10">
          <div className="lg:w-2/5 md:w-2/5 sm:w-1/2 pr-4 pl-4">
            <div className="footer-box-two">
              <h3 className="h3-title font-header">پیوندهای ما</h3>
              <div className="line"></div>
              <ul>
                {menu.map(item => {
                  return (
                    <li key={item.label}>
                      <Link to={item.href}>{item.label}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="flex-grow pr-4 pl-4">
            <div className="footer-box-three">
              <h3 className="h3-title font-header">ارتباط با ما</h3>
              <div className="line"></div>
              <ul>
                <li>
                  <div className="footer-contact-icon">
                    <IcSharpLocationOn />
                  </div>
                  <div className="footer-contact-text">
                    <span>مازندران - ساری - بلوار طالقانی - نبش خیابان توانبخشی - کوچه شفا</span>
                  </div>
                </li>
                <li>
                  <div className="footer-contact-icon">
                    <IcBaselineLocalPhone />
                  </div>
                  <a href="tel:+989339119201" className="footer-contact-text">
                    <span dir="ltr">0933 911 9201</span>
                  </a>
                </li>
                <li>
                  <div className="footer-contact-icon">
                    <MdiInstagram />
                  </div>
                  <a href="https://instagram.com/MARIAN.SPORTS" className="footer-contact-text">
                    <span dir="ltr">@MARIAN.SPORTS</span>
                  </a>
                </li>
                <li>
                  <div className="footer-contact-icon">
                    <MdiTelegram />
                  </div>
                  <a href="https://t.me/MARIAN_SPORTS" className="footer-contact-text">
                    <span dir="ltr">@MARIAN_SPORTS</span>
                  </a>
                </li>
                <li>
                  <div className="footer-contact-icon">
                    <IcBaselineEmail />
                  </div>
                  <Link to="mailto:marianclub18@gmail.com" className="footer-contact-text">
                    <span>Marianclub18@gmail.com</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center flex justify-center py-2 mt-10 ">
        <p className="text-gray-400">
          تمام حقوق مادی و معنوی این وبسایت متعلق به وبسایت{' '}
          <Link to="/" className="text-white">
            marian-sports.com
          </Link>{' '}
          می باشد
        </p>
      </div>
    </footer>
  )
}

import { motion } from 'framer-motion'
export const Header = () => {
  return (
    <section className="main-banner max-h-screen h-screen min-h-[700px] pt-[160px] flex flex-col" id="M1">
      <div className="banner-overlay-bg animate-this">
        <img src="/images/banner-overlay.png" alt="Overlay" />
      </div>
      <div className="banner-blur-bg">
        <img src="/images/blur-1.png" alt="Blur" />
      </div>
      <div className="container mx-auto sm:px-4 pb-10 h-full flex-grow flex flex-col ">
        <div className="flex flex-grow flex-col h-full justify-center items-center max-h-full gap-6">
          <div className="md:flex-grow pr-4 pl-4 max-h-[800px] max-w-full overflow-hidden aspect-square w-full">
            <div className="main-banner-img-one h-full max-h-full justify-center flex relative">
              <motion.img
                src="/images/guy.png"
                alt="Banner"
                className="h-full max-h-full z-20"
                viewport={{ once: true }}
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0, duration: 0.3, ease: 'easeOut' }}
              />
              <div className="absolute top-0 h-full aspect-square z-10 scale-[0.9]">
                <motion.img
                  viewport={{ once: true }}
                  src="/images/banner-circle-one.png"
                  alt="Circle"
                  className="absolute top-0 h-full aspect-square"
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.2, duration: 0.3, ease: 'easeOut' }}
                />
                <motion.img
                  viewport={{ once: true }}
                  src="/images/banner-circle-two.png"
                  alt="Circle"
                  className="absolute top-0 h-full aspect-square"
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.1, duration: 0.2, ease: 'easeOut' }}
                />
              </div>
            </div>
          </div>
          <div className="md:px-4">
            <div className="banner-title-one flex h-full flex-col items-center gap-6">
              <motion.h1 className="h1-title font-header" initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0, duration: 0.2, ease: 'easeOut' }}>
                مـآریان
              </motion.h1>
              <motion.p className="text-xl max-w-[230px] sm:max-w-full" initial={{ opacity: 0, y: 20 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2, duration: 0.2, ease: 'easeOut' }}>
                جایی که ما معتقدیم کیفیت و اهمیت با ارزش‌تر از هر چیز دیگری است
              </motion.p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

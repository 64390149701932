import clsx from 'clsx'
import { motion } from 'framer-motion'

const initial = { opacity: 0, y: 40 }
const whileInView = { opacity: 1, y: 0 }
const viewport = { once: true }
const transition = { duration: 0.4, delay: 0.1 }

const contactLink = 'https://t.me/marian_sports'
const plans = [
  {
    name: 'رژیم غذایی',
    price: '450 هزار تومان',
    description: 'ارائه رژیم غذایی تحت نظر متخصص تغذیه',
    className: 'bg-gray-400',
    btnText: 'دریافت رژیم غذایی',
  },
  {
    name: 'برنامه تمرینی',
    price: '450 هزار تومان',
    description: 'برنامه ورزشی اختصاصی با توجه به شرایط و توانایی های شما',
    className: 'bg-gray-400',
    btnText: 'دریافت برنامه تمرینی',
  },
  {
    name: 'کامل',
    price: '850 هزار تومان',
    description: 'رژیم غذایی به همراه برنامه تمرینی',
    className: 'bg-gray-300',
    btnText: 'دریافت برنامه کامل',
  },
  {
    name: 'متمرکز',
    price: '1,350 هزار تومان',
    description: 'رژیم غذایی به همراه برنامه تمرینی و دوجلسه کوچینگ حضوری/غیر حضوری (سوال/جواب، آنالیز بدنی، مشاوره)',
    className: 'bg-gray-200',
    btnText: 'دریافت برنامه متمرکز',
  },
  {
    name: 'حرفه ای',
    price: '1,850 هزار تومان',
    description: 'رژیم غذایی به همراه برنامه تمرینی و دوجلسه کوچینگ حضوری/غیر حضوری (سوال/جواب، آنالیز بدنی، مشاوره) همچنین امکان تماس مستقیم با مربی در ساعات کاری',
    className: 'bg-gray-100',
    btnText: 'دریافت برنامه حرفه ای',
  },
]
export const Pricing = () => {
  return (
    <section className="flex flex-col gap-10 md:py-[130px] py-[80px]" id="M4">
      <div className="container mx-auto sm:px-4">
        <div className="flex flex-wrap ">
          <div className="lg:w-full">
            <div className="pricing-title flex items-center flex-col gap-4">
              <motion.h2 initial={initial} whileInView={whileInView} viewport={viewport} transition={transition} className="h2-subtitle">
                درخواست برنامه ورزشی غیر حضوری
              </motion.h2>
              <motion.h6
                initial={initial}
                whileInView={whileInView}
                viewport={viewport}
                transition={{
                  delay: 0.5,
                  ease: 'easeOut',
                }}
                className="text-gray-200 text-lg md:mt-4 mt-2 md:max-w-[800px] mx-auto text-justify"
                style={{ textAlignLast: 'center' }}
              >
                برنامه تمرینی درخواستی شما بر اساس اهدافی که اعلام می کنید و با در نظر گرفتن شرایط فیزیکی، آسیب های احتمالی، جنسیت، محدودیت های تجهیزاتی و ... مختص شخص شما بطور کامل توسط سرمربی
                مجموعه، سجاد شکری طراحی و تنظیم می شود.
              </motion.h6>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-10 md:gap-8 pricing-slider container mx-auto sm:px-4 mt-8  max-w-[1100px]">
        {plans.map((plan, index) => {
          return (
            <motion.div
              className="flex-grow"
              key={plan.name}
              initial={initial}
              whileInView={whileInView}
              viewport={viewport}
              transition={{
                ...transition,
                delay: transition.delay + index / 9,
              }}
            >
              <div className="h-full pricing-box wow animate__fadeInUp border border-white rounded-md pb-6" data-wow-delay=".5s">
                <div className={clsx('pricing-title-box pricing-one rounded-md text-right px-3 py-2', plan.className)}>
                  <h3 className="h3-title">{plan.name}</h3>
                  <h2 className="h2-title">
                    {plan.price}
                    <span>/ماهانه</span>
                  </h2>
                </div>
                <div className="pricing-content-box px-8 gap-4 flex flex-col items-center justify-between ">
                  <p className="max-w-[300px] text-gray-200 text-justify" style={{ textAlignLast: 'center' }}>
                    {plan.description}
                  </p>
                  <a href={contactLink} target="_blank" className="bg-transparent border-2 text-white px-4 py-2 rounded-md" rel="noreferrer">
                    {plan.btnText}
                  </a>
                </div>
              </div>
            </motion.div>
          )
        })}
      </div>
    </section>
  )
}
